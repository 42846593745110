// floating label en los input 

ion-segment{
  // margin-top: 60px;
  ion-segment-button{
    color: var(--ion-color-medium);
    border-bottom-style: solid;
    border-bottom-width: thin;
    max-width: none;
    text-transform: none;
    font-size: 18px;
  }
}