/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* imports css AFP */
@import "./assets/scss/floating.scss";
@import "./assets/scss/segment_afp.scss";

.barraTop {
    height: 16px;
    background-color: var(--ion-color-botonAfp);
}

form {
    margin: 0;
    font: inherit;
    color: black;
}

.loading-capital-class {
    --background: var(--ion-color-toolbar, #EFEF6C);
    --max-width: 280px;
    --max-height: 90%;
    --spinner-color: #2650BA;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    color: #2650BA;
    font-size: 14px;
}

// clases para el header
.toolbar{
    padding-left: 100px;
    padding-right: 100px;
}
.logoHeader{
    width: 110px;
    margin: 14px 16px 14px 0px;
}

.container{  
    // padding: 0px 16px;
    max-width: 968px;
    // margin-left: auto;
    // margin-right: auto;
}
.paddingContainer{
    padding: 0 24px;
}

.itemBackButton{
    margin-bottom: 40px; 
    --padding-start: 0px;
}

.itembox {  
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    border-color: black;
    // max-width: 504px;
    // margin-left: calc( 50% - 125px );
}
.invalidColor{
    border-color: var(--ion-color-danger) !important;
    // color: var(--ion-color-danger) !important;
}
.itemboxInvalid {
    border-color: var(--ion-color-danger) !important;
}
.labelInvalid {
    color: var(--ion-color-danger) !important;
}
.labelvalid {
    color: #000000 !important;
}
.invalidMsg{
    font-size: 14px;
    margin-left: 8px;
    color: var(--ion-color-danger);
}
// para unificar la opacidad del disabled de los input con otros componentes
.native-input[disabled].sc-ion-input-md  {
    opacity: unset;
}
// para el popover del tipo de cuenta en movimiento de cliente.
.popoverCuenta .popover-content{ 
    width: 350px; 
    .select-interface-option{
        font-size: 20px;
    }
}
// para el popover del tipo de periodo cuatrimestral en cartolas de certificados.
.popoverPeriodoCuatrimestral .popover-content{ 
    width: 450px;
    .select-interface-option{
        font-size: 20px;
    }
}

.multiplicador {
    &__modal {
        &-content {
            .modal-wrapper {
                width: 490px;
                border-radius: 32px;
            }
        }
    }
}

.icon-no-hover {
    pointer-events: none !important;
}
ion-datetime{
    color: #000000;
}
.datetime-placeholder {
    color: var(--placeholder-color) !important;
}