// floating label en los input 

.label-float {
    position: relative;
    padding-top: 13px;
    &.invalid {
      ion-label {
        color: var(--ion-color-danger) !important;
      }
      ion-input, ion-textarea {
        border: 2px solid var(--ion-color-danger) !important;
      }
    }
    &.disabled {
      ion-label {
        color: var(--ion-color-disabled) !important;
      }
      ion-input, ion-textarea {
        border: 2px solid var(--ion-color-disabled) !important;
        color: var(--ion-color-disabled)
      }
    }
  
    ion-input, ion-datetime, ion-textarea {
      background-color: transparent;
      border: 2px solid #000;
      border-radius: 10px;
      outline: none;
    //   min-width: 250px;
      padding: 15px 13px !important;
      font-size: 20px;
      transition: all 0.1s linear;
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      -webkit-appearance: none;
      margin-top: 0px;
      &:focus {
        border: 2px solid #000;
      }
      &::placeholder {
        color: transparent;
      }
    }
    ion-label {
      color: #000;
      pointer-events: none;
      position: absolute;
      top: calc(50% - 8px);
      left: 10px;
      transition: all 0.1s linear;
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      background-color: #fff;
      z-index: 99;
      padding-top: 5px;
      margin-left: 0px;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
    }
    
    ion-input:focus + ion-label, ion-input:not(:placeholder-shown):not(.invalid) + ion-label {
      font-size: 14px;
      top: 0;
      color: #000;
    }
    ion-input:not(:focus):not(:placeholder-shown):not(.invalid) + ion-label {
      font-size: 14px;
      top: 0;
      color: #000;
    }
    ion-textarea:focus + ion-label, ion-textarea:not(:placeholder-shown):not(.invalid) + ion-label {
      font-size: 14px;
      top: 0;
      color: #000;
    }
    ion-textarea:not(:focus):not(:placeholder-shown):not(.invalid) + ion-label {
      font-size: 14px;
      top: 0;
      color: #000;
    }

    ion-datetime:focus + ion-label, ion-datetime:not(:placeholder-shown):not(.invalid) + ion-label {
      font-size: 14px;
      top: 0;
      color: #000;
    }
    ion-datetime:not(:focus):not(:placeholder-shown):not(.invalid) + ion-label {
      font-size: 14px;
      top: 0;
      color: #000;
    }
  }
  


.float-certificado {
  position: relative;
  padding-top: 13px;
  margin-bottom: 20px;
  &.invalid {
    label {
      color: #f53d3d !important;
    }
    input {
      border: 2px solid #f53d3d !important;
    }
  }

  input {
    background-color: transparent;
    border: 2px solid #000000;
    border-radius: 10px;
    outline: none;
    width: 100%;
    padding: 15px 20px !important;
    font-size: 20px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -webkit-appearance: none;
    &:focus {
      border: 2px solid #3951b2;
    }
    &::placeholder {
      color: transparent;
    }
  }
  label {
      color: #000000;
      pointer-events: none;
      position: absolute;
      top: calc(50% - 8px);
      left: 20px;
      transition: all 0.1s linear;
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      background-color: #fff;
      z-index: 99;
      padding-top: 5px;
      // margin-left: 8px;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
        font-size: 20px;

  }


  input:focus + label, input:not(:placeholder-shown):not(.invalid) + label {
      font-size: 13px;
      top: 0;
      color: #3951b2;
  }
  
  input:not(:focus):not(:placeholder-shown):not(.invalid) + label {
      font-size: 13px;
      top: 0;
      color: #000;
  }

  ion-icon{
      position: absolute;
      top: 34px;
      right: 15px;
      font-size: 24px;
      color: var(--ion-color-btnlightBlue);
      cursor: pointer;
  }
          
}

.label-float-select {
  position: relative;
  padding-top: 13px;
  // padding-left: 16px;
  margin-bottom: 20px;
  min-width: 370px;

  ion-select::part(icon) {
      display: none;
  }

  ion-select {
    background-color: transparent;
    border: 2px solid #000000;
    border-radius: 10px;
    outline: none;
    min-width: 250px;
  //   max-width: 350px;
  //   width: 350px;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 400;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -webkit-appearance: none;
    min-height: 58px;
    &::placeholder {
      color: transparent;
    }
  }
  ion-label  {
    color: #000000;
    pointer-events: none;
    position: absolute;
    top: calc(50% - 8px);
    left: 20px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    background-color: #fff;
    z-index: 99;
    padding-top: 5px;
    // margin-left: -11px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    font-size: 20px;
  }
  .floatLabel{
      font-size: 14px;
      top: 0 !important;
      color: #000000 !important;
  }
  ion-icon{
      position: absolute;
      top: 34px;
      right: 15px;
      font-size: 24px;
  }
}
  